import { Button } from '@/components/ui/button';

export function ComponentError() {

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="text-center">
        <img src="/images/no-internet.png" alt="Page not found" className="h-40 mx-auto mb-4" />
        <p className="text-lg text-gray-700">
          Oops! Something went wrong.
        </p>
        <p className="text-sm text-gray-600 mb-6">
        </p>
        <Button onClick={() => { handleRefresh(); }}>
          Refresh Page
        </Button>
      </div>
    </div>
  );
}
