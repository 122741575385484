import { useEffect } from 'react';
import { org_code } from "@/env-vars";

const tawkToUrls = {
    GBKL: 'https://embed.tawk.to/66ac46b31601a2195b9fba1c/1i48f8buh',
    FLM: 'https://embed.tawk.to/66acb23132dca6db2cb925fc/1i499g4pn',
};

export const TawkTo = () => {
    useEffect(() => {
        const tawkToUrl = tawkToUrls[org_code];
        if (tawkToUrl && tawkToUrl.trim() !== '') {
            var Tawk_API = Tawk_API || {};
            (function () {
                var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                s1.async = true;
                s1.src = tawkToUrl;
                s1.setAttribute('crossorigin', '*');
                s0.parentNode.insertBefore(s1, s0);
            })();
        }
    }, []);
    return null;
};