import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LoadingSpinner } from "@/components/ui/loading-spinner";

const Layout = lazy(() => import('@/components/Layout').then(module => ({ default: module.Layout })));
const Login = lazy(() => import('@/views/auth/Login').then(module => ({ default: module.Login })));
const Register = lazy(() => import('@/views/auth/Register').then(module => ({ default: module.Register })));
const ForgotPassword = lazy(() => import('@/views/auth/ForgotPassword').then(module => ({ default: module.ForgotPassword })));
const ResetPassword = lazy(() => import('@/views/auth/ResetPassword').then(module => ({ default: module.ResetPassword })));
const Profile = lazy(() => import('@/views/account/Profile').then(module => ({ default: module.Profile })));
const InvoiceDetail = lazy(() => import('@/views/invoice/invoice-detail/InvoiceDetail').then(module => ({ default: module.InvoiceDetail })));
const InvoiceList = lazy(() => import('@/views/invoice/invoice-list/InvoiceList').then(module => ({ default: module.InvoiceList })));
const ClaimInvoice = lazy(() => import('@/views/invoice/invoice-claim/ClaimInvoice').then(module => ({ default: module.ClaimInvoice })));
const ActivateAccountSendEmail = lazy(() => import('@/views/auth/ActivateAccountSendEmail').then(module => ({ default: module.ActivateAccountSendEmail })));
const SetPassword = lazy(() => import('@/views/auth/SetPassword').then(module => ({ default: module.SetPassword })));
const SuccessMessage = lazy(() => import('@/views/auth/SuccessMessage').then(module => ({ default: module.SuccessMessage })));
const EInvoicePrint = lazy(() => import('@/views/invoice/print/EInvoicePrint').then(module => ({ default: module.EInvoicePrint })));
const FirstTimeUser = lazy(() => import('@/views/auth/FirstTimeUser').then(module => ({ default: module.FirstTimeUser })));
const Instruction = lazy(() => import('@/views/auth/Instruction').then(module => ({ default: module.Instruction })));
const CreateBulkExport = lazy(() => import('@/views/invoice/bulk-export/CreateBulkExport').then(module => ({ default: module.CreateBulkExport })));
const BulkExportList = lazy(() => import('@/views/invoice/bulk-export/BulkExportList').then(module => ({ default: module.BulkExportList })));

export const AppRoutes = () => (
    <Suspense fallback={(
        <div className="flex items-center justify-center h-full" style={{ minHeight: "calc(100vh - 65px)" }}>
            <LoadingSpinner size={35} className="text-primary" />
        </div>
    )}>
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/activate-account-send-email" element={<ActivateAccountSendEmail />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route path="/success-message" element={<SuccessMessage />} />
            <Route path="*" element={<Navigate to="/login" />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/e-invoice-print" element={<EInvoicePrint />} />
            <Route path="/first-time-user" element={<FirstTimeUser />} />
            <Route path="/instruction" element={<Instruction />} />

            <Route element={<Layout />}>
                <Route path="/claim-invoice/:guid" element={<ClaimInvoice />} />
                <Route path="/invoice-list" element={<InvoiceList />} />
                <Route path="/invoice-detail/:guid" element={<InvoiceDetail />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/create-bulk-export" element={<CreateBulkExport />} />
                <Route path="/bulk-export-list" element={<BulkExportList />} />
            </Route>
        </Routes>
    </Suspense>
);
